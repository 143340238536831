
import { Options, Vue } from 'vue-class-component';
import HelloWorldComponent from '@/components/HelloWorld/index.vue';
import ProfileLayout from '@/layouts/Profile/index.vue';

@Options({
    components: {
        HelloWorldComponent,
        ProfileLayout,
    },
    data() {
        return {
            accounts: [],
        };
    },
    created: function () {
        this.getIGAccounts();
    },
    methods: {
        getIGAccounts: function () {
            let self = this;
            this.$store
                .dispatch('user/getIGAccounts')
                .then((data) => {
                    console.log(data);
                    self.accounts = data;
                })
                .catch((erorr) => {
                    console.log(erorr);
                });
        },
    },
})
export default class Media extends Vue {
    public message = 'Feed -> Media';
}
